
export default {
  name: 'NavigationDrawer',
  components: {
    NavigationLinks: () => import(/* webpackChunkName: "NavigationLinksResponsive" */ './NavigationLinksResponsive'),
    NavigationAuth: () => import(/* webpackChunkName: "NavigationAuth" */ './NavigationAuth'),
    SiteSearch: () => import(/* webpackChunkName: "SiteSearch" */ '../../../components/ui/SiteSearch.vue'),
    Logo: () => import(/* webpackChunkName: "Logo" */ '../../../components/ui/Logo')
  }
}
